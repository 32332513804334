@import '../../../styles/mixins/responsive';
@import '../../../styles/layout';

.widthWrapper {
    @apply overflow-hidden tablet-landscape:absolute tablet-landscape:right-0 tablet-landscape:mt-36;

    @include from-tablet-landscape {
        height: 95rem;
        width: columnWidth(5)
    }
    @include from-desktop {
        width: columnWidth(6)
    }
    @include large-desktop {
        width: columnWidth(8)
    }
    @include fullhd {
        width: columnWidth(9)
    }
}

.wrapper {
    @apply relative ml-8 -mt-28 tablet:ml-20 tablet:-mt-60 tablet-landscape:mt-12;
    width: 26.8rem;
    height: 30.3rem;
    transform: rotateY(180deg) rotateX(180deg) rotateZ(90deg);

    &:hover {
        .firstOrangeWrapper {
            .orange {
                animation: shapeAnimation 1.5s, pulse 2s infinite;
            }
        }
    }

    @include from-tablet {
        width: 42rem;
        height: 47.5rem;
    }

    @include from-tablet-landscape {
        width: 56.625rem;
        height: 64rem;
        transform: none;
        margin-left: columnWidth(1, 5);
    }

    @include from-desktop {
        margin-left: offsetWidth(1, 6);
    }

    @include large-desktop {
        margin-left: offsetWidth(1, 8);
    }

    @include fullhd {
        margin-left: offsetWidth(1, 9);
    }
}

.inner {
    @apply absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 -rotate-45;
    width: 95.36%;
    height: 112.1%;
}

.dimensions {
    @apply absolute;
    width: 31%;
    height: 48.6%;
}

.greenWrapper {
    @apply top-1/2 right-0 -translate-y-1/2 hidden tablet-landscape:block;
    .green {
        animation: shapeAnimation 1.5s 1.2s both;
    }
}

.redWrapper {
    @apply top-1/2 left-0 -translate-y-1/2;
    .red {
        animation: shapeAnimation 1.5s 0.8s both;
    }
}

.firstOrangeWrapper {
    @apply left-1/2 top-0 -translate-x-1/2;
    .orange {
        animation: shapeAnimation 1.5s, pulse 2s 2s 3;
    }
}

.secondOrangeWrapper {
    @apply left-1/2 bottom-0 -translate-x-1/2;
    .orange {
        animation: shapeAnimation 1.5s 1s both;
    }
}

.imageClip {
    @apply absolute top-0 left-0 w-full h-full;
    clip-path: inset(0rem -100% 0% 0% round 4rem 0rem 0rem 0rem);

    @include from-tablet {
        clip-path: inset(0rem -100% 0% 0% round 6rem 0rem 0rem 0rem);
    }

    @include from-tablet-landscape {
        clip-path: inset(0rem -100% 0% 0% round 0rem 0rem 0rem 8.3rem);
    }
}

.imageWrapper {
    @apply absolute;
    width: 190%;
    bottom: -12%;
    left: -40%;
    animation: imageAnimationMobile 0.5s 2s both;

    @include from-tablet-landscape {
        animation: imageAnimation 0.5s 2s both;
        bottom: 4%;
        left: -30%;
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(245, 190, 96, 0.7);
    }

    70% {
        box-shadow: 0 0 0 4rem rgba(245, 190, 96, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(245, 190, 96, 0);
    }
}

@keyframes shapeAnimation {
    0% {
        height: 200%;
        transform: translateY(200%);
    }

    80% {
        height: 200%;
        transform: translateY(0);
    }

    100% {
        height: 100%;
    }
}

@keyframes imageAnimation {
    0% {
        opacity: 0;
        transform: scale(0.95) rotate(45deg) translateY(2rem);
    }

    100% {
        opacity: 1;
        transform: scale(1) rotate(45deg) translate(0);
    }
}

@keyframes imageAnimationMobile {
    0% {
        opacity: 0;
        transform: scale(0.95) rotate(135deg) translateY(2rem);
    }

    100% {
        opacity: 1;
        transform: scale(1) rotate(135deg) translate(0);
    }
}
